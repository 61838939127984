<template>
  <div
    v-if="!meansOfPayment.length || loading"
    class="pst-center d-flex justify-center"
  >
      <v-progress-circular
        color="primary"
        class="align-self-center"
        indeterminate
        :size="100"
        :width="7"
      ></v-progress-circular>
  </div>
  <v-card
    v-else
  >
    <snack-view
      :message="snack.message"
      :activate="snack.active"
      :bg-color="snack.color"
    ></snack-view>
    <v-card-title class="font-weight-bold text-uppercase">
      Paiement du deposit
    </v-card-title>
    <v-divider></v-divider>
    <v-container>
      <v-form
        ref="refillForm"
        class="multi-col-validation col-md-10 offset-md-1"
      >
        <fieldset class="py-6 mb-4">
          <legend class="ml-4 pl-2 pr-2">
            Type de paiement
          </legend>
          <v-row class="mx-auto">
            <v-col
              cols="12"
              md="6"
              :offset-md="`${IS_MOBILE_TYPE? '0':'3'}`"
            >
              <v-select
                v-model="refill.typeDeposit"
                outlined
                dense
                :rules="LocalNameRules"
                :items="typeOfDeposit"
                item-value="id"
                item-text="label"
                :menu-props="{ offsetY : true }"
                placeholder="Type de dépôt"
                label="Type de dépôt"
                hide-details="auto"
                return-object
                @change="getTypeDeposit"
              ></v-select>
            </v-col>
            <v-col
              v-if='IS_MOBILE_TYPE'
              cols="12"
              md="6"
            >
              <v-select
                v-model="refill.paymentCodeCredit"
                outlined
                dense
                :rules="LocalNameRules"
                :items="moyenPayment"
                :menu-props="{ offsetY : true }"
                item-value="code"
                item-text="label"
                placeholder="Moyen de paiement"
                label="Moyen de paiement"
                hide-details="auto"
                return-object
                @change="getTypePaiement"
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              md="6"
              v-if='!IS_MOBILE_TYPE'
            >
              <v-select
                v-model="refill.paymentCodeDebit"
                outlined
                dense
                :rules="LocalNameRules"
                :items="moyenPayment"
                :menu-props="{ offsetY : true }"
                item-value="code"
                item-text="label"
                :disabled="!refill.typeDeposit"
                placeholder="Moyen de paiement débiteur"
                label="Moyen de paiement débiteur"
                hide-details="auto"
                return-object
                @change="getTypePaiementDebit"
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              md="6"
              v-if='!IS_MOBILE_TYPE'
            >
              <v-select
                v-model="refill.paymentCodeCredit"
                outlined
                dense
                :disabled="!refill.typeDeposit"
                :rules="LocalNameRules"
                :items="moyenPayment"
                :menu-props="{ offsetY : true }"
                item-value="code"
                item-text="label"
                placeholder="Moyen de paiement créditeur"
                label="Moyen de paiement créditeur"
                hide-details="auto"
                return-object
                @change="getTypePaiement"
              ></v-select>
            </v-col>
          </v-row>
        </fieldset>
        <fieldset class="py-6 mb-4">
          <legend class="ml-4 pl-2 pr-2">
            Compte
          </legend>
          <v-row class="mx-auto">
            <v-col
              cols="12"
              md="6"
            >
              <v-select
                v-model="refill.compteDebite"
                v-mask="mask"
                label="Compte débité"
                :items="listAccountDebite"
                outlined
                dense
                :disabled="!refill.typeDeposit"
                :rules="LocalNameRules"
                placeholder="Compte débité"
                :append-outer-icon="icon.mdiPlusCircle"
                @click:append-outer="openAccountDialog"
              >
              </v-select>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="refill.compteCredite"
                outlined
                readonly
                dense
                :disabled="!refill.typeDeposit"
                :rules="LocalNameRules"
                placeholder="Compte crédité"
                label="Compte crédité"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
        </fieldset>
        <fieldset class="py-6 mb-4">
          <legend class="ml-4 pl-2 pr-2">
            Paiement
          </legend>
          <v-row class="mx-auto">
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                :suffix="currency"
                :value="amountTotext"
                min="0"
                label="Deposit"
                :rules="LocalNameRules"
                outlined
                readonly
                disabled
                dense
                placeholder="montant du deposit"
                hide-details="auto"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="refill.reference"
                label="Référence paiement"
                :rules="LocalNameRules"
                outlined
                dense
                placeholder="Référence paiement"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-file-input
                v-model="refill.facture"
                :accept="filesAccepted"
                label="Reçu"
                outlined
                dense
                class="p-0"
                show-size
                placeholder="Réçu de paiement"
                :rules="LocalNameRules"
              >
                <v-icon slot="prependIcon">
                  {{ icon.mdiPaperclip }}
                </v-icon>
              </v-file-input>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-menu
                v-model="refill.menu3"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formatDate"
                    label="Date de remontée"
                    :readonly="true"
                    v-bind="attrs"
                    :rules="LocalNameRules"
                    outlined
                    dense
                    v-on="on"
                  >
                    <template v-slot:append>
                      <v-icon>{{ icon.mdiCalendarMonth }}</v-icon>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="refill.dateRemonte"
                  color="primary"
                  :show-current="true"
                  :min="yesterdayDate"
                  :max="currentDate"
                  locale="pr-fr"
                  @input="refill.menu3 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </fieldset>
        <v-row
          class=""
        >
          <v-col
            cols="12"
            class="d-sm-flex flex-sm-row-reverse"
          >
            <v-btn
              color="primary"
              class="ml-sm-6 col-sm-2 mt-1 mb-2"
              :disabled="loading"
              @click="saveRefill"
            >
              Payer
            </v-btn>
            <v-btn
              type="button"
              class="ml-sm-6 col-sm-2 mt-1 mb-2"
              outlined
              :disabled="loading"
              @click="resetForm"
            >
              Annuler
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
    <div
      v-if="!meansOfPayment.length && loading"
      class="pst-center d-flex justify-center"
    >
      <v-progress-circular
        color="primary"
        class="align-self-center"
        indeterminate
        :size="100"
        :width="7"
      ></v-progress-circular>
    </div>
    <v-dialog
      v-model="addAccountDialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          Ajout d'un compte {{ refill.paymentCodeDebit?refill.paymentCodeDebit.label:'' }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form
            ref="addAccountRef"
            :disabled="refill.paymentCodeDebit==''"
          >

            <v-row
              v-for="(payment,paymentIndex) in accountByPayment"
              :key="paymentIndex"
            >
              <v-col
                cols="12"
              >
                <div class='d-flex'>
                  <v-text-field
                    v-model="payment.paymentAccount"
                    v-mask="mask"
                    outlined
                    :rules="phoneNumberRules"
                    dense
                    label="Numéro de compte"
                    placeholder="numéro de compte"
                    hide-details="auto"
                  >
                  </v-text-field>
                  <v-btn
                    v-if="paymentIndex == (accountByPayment.length - 1)"
                    color="primary"
                    text
                    @click="addNewAccount"
                    :disabled="refill.paymentCodeDebit==''"
                  >
                    <v-icon>{{ icon.mdiPlusCircle }}</v-icon>
                  </v-btn>
                  <v-btn
                    v-else

                    color="error"
                    text
                    @click="accountByPayment.splice(paymentIndex,1)"
                  >
                    <v-icon>{{ icon.mdiCloseCircle }}</v-icon>
                  </v-btn>
                </div>

              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            @click="closeAccountDialog"
          >
            Annuler
          </v-btn>
          <v-btn
            color="primary"
            :disabled="disableAccount"
            @click="addAccount"
          >
            Ajouter
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="errorModal"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          Erreur
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <span class=''>Veuillez selectionner un moyen de paiement!</span>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="errorModal=false"
          >
            ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import {
  mdiPaperclip, mdiCalendarMonth, mdiPlus, mdiPlusCircle, mdiCloseCircle,
} from '@mdi/js'
import {
  computed, nextTick, onMounted, reactive, ref,
} from '@vue/composition-api'
import Http from '@/helpers/http'
import moment from 'moment/moment'
// eslint-disable-next-line import/extensions
import SnackView from '@/views/SnackView'
import {
  VIREMENT, DEPOT, TYPE_PAYMENT_MOBILE, MOBILE,
} from '@/helpers/constants/refill'
import router from '@/router'
import useDynamicConstant from '@/helpers/useDynamicConstant'

export default {
  name: 'PayForm',
  components: {
    SnackView,
  },
  props: {
    montant: {
      type: [Number, String],
      required: true,
    },
  },
  setup(props, { emit, refs }) {
    const refillForm = ref(null)

    const snack = ref({
      message: '',
      active: false,
      color: undefined,
    })

    const loadPage = ref(true)

    // Règle de validation d'un champ
    const LocalNameRules = [
      v => !!v || 'Ce champ est réquis',
    ]

    // Type de fichier accepté par le champ Reçu
    const filesAccepted = [
      '.txt', 'image/jpg', 'image/png', 'image/*',
    ]

    const loading = ref(false)

    // const typeOfDeposit = ref([]) // pour le champ type de dépôt
    const typeOfDeposit = [
      {
        id: DEPOT,
        label: 'Versement',
        type: 2,
        mobile: 3,
      },
      {
        id: VIREMENT,
        label: 'Virement',
        type: 3,
        mobile: 3,
      },
      {
        id: MOBILE,
        label: 'Mobile',
        type: 1,
        mobile: 2,
      },
    ] // pour le champ type de dépôt

    const saveRefill = () => {
      snack.value.active = false
      if (refs.refillForm.validate()) {
        loading.value = true

        const contrat = JSON.parse(localStorage.getItem('defaultContrat'))

        const params = new FormData()
        // eslint-disable-next-line no-use-before-define
        params.append('contractId', contrat.id)
        params.append('customer_id', contrat.customer_id)
        // eslint-disable-next-line no-use-before-define,radix
        params.append('amount', parseInt(refill.value.montant))
        // eslint-disable-next-line no-use-before-define,radix
        params.append('paymentCode', refill.value.moyenPaiement.code)
        // eslint-disable-next-line no-use-before-define,radix
        params.append('depotType', parseInt(refill.value.typeDeposit.id))
        // eslint-disable-next-line no-use-before-define
        params.append('ref', refill.value.reference)
        // eslint-disable-next-line no-use-before-define
        params.append('compteC', refill.value.compteCredite)
        // eslint-disable-next-line no-use-before-define
        params.append('compteD', refill.value.compteDebite)
        // eslint-disable-next-line no-use-before-define
        params.append('paymentCodeDebit', refill.value.paymentCodeDebit.code)
        // eslint-disable-next-line no-use-before-define
        params.append('paymentCodeCredit', refill.value.paymentCodeCredit.code)
        // eslint-disable-next-line no-use-before-define
        params.append('file', refill.value.facture)
        // eslint-disable-next-line no-use-before-define
        params.append('dateRefill', refill.value.dateRemonte)

        Http.post(
          'register-bond',
          params,
        ).then(response => {
          loading.value = false
          if (response.status === 201) {
            snack.value.message = response.message
            snack.value.active = true
            // eslint-disable-next-line no-use-before-define
            resetForm()
            setTimeout(() => {
              router.push({ name: 'list-caution-encours' })
            }, 2000)
          } else {
            snack.value.message = response.data.message
            snack.value.color = 'error'
            snack.value.active = true
          }
          // eslint-disable-next-line no-use-before-define
        }).catch(error => {
          loading.value = false
          console.log(error)
        })
      }
    }

    // eslint-disable-next-line no-use-before-define
    const formatDate = computed(() => (refill.value.dateRemonte
      // eslint-disable-next-line no-use-before-define
      ? moment(refill.value.dateRemonte).format('DD-MM-YYYY')
      : ''))

    const currency = ref('XAF')
    const meansOfPayment = ref([]) // pour le champ moyen de paiement

    const moyenPayment = ref([]) // pour le champ moyen de paiement
    const accountByPaymentMethod = ref([]) // pour le champ moyen de paiement
    const accountCustomerByPaymentMethod = ref([]) // pour le champ moyen de paiement

    // const loadMeansOfPayment = () => {
    //   Http.get('get-paymode')
    //     .then(response => {
    //       meansOfPayment.value = response.data
    //       console.log(response.data)
    //     })
    //     .catch(error => error)
    // }

    const loadMeansOfPayment = () => {
      Http.get('get-paymode')
        .then(response => {
          meansOfPayment.value = response.data
          moyenPayment.value = response.data
          console.log(response.data)
          loading.value = false
        })
        .catch(error => error)
    }

    const loadAccountByPaymentMethod = () => {
      Http.get('get-payment-account')
        .then(response => {
          accountByPaymentMethod.value = Object.entries(response.accountByPaymode)
          accountCustomerByPaymentMethod.value = Object.entries(response.accountByPaymodeOfCustomer)
          console.log('get-payment-account')
          console.log(accountByPaymentMethod.value)
          loadMeansOfPayment()
        })
        .catch(error => error)
    }

    const fixedAccountCredite = () => {
      console.log('fixedAccountCredite')

      // eslint-disable-next-line array-callback-return
      const result = accountByPaymentMethod.value.filter(payment => {
        // payment[0] === refill.value.paymentCodeCredit.code
        console.log(payment[0])
        const payCodeCredit = (refill.value.paymentCodeCredit.code).toUpperCase()

        return payment[0] === payCodeCredit
      })[0]

      console.log(result)
      // eslint-disable-next-line no-use-before-define
      refill.value.compteCredite = ''
      refill.value.compteCredite = result[1][0]
    }

    const listAccountDebite = computed(() => {

     let code = refill.value.paymentCodeDebit.code !== undefined ? refill.value.paymentCodeDebit.code.toUpperCase():null
      const result = accountCustomerByPaymentMethod.value.filter(payment => (payment[0].toUpperCase() === code))
      console.log(result)

      refill.value.compteDebite = ''

      return result.length ? result[0][1] : result
    })

    const IS_MOBILE_TYPE = ref(false)

    const mask = ref('#####-#####-###########-##')

    const getTypePaiement = () => {
      const type = meansOfPayment.value.filter(el => el === refill.value.paymentCodeCredit)

      if (type.length && parseInt(type[0].type) === TYPE_PAYMENT_MOBILE) {
        mask.value = '#########'
        refill.value.compteCredite = ''
        if(IS_MOBILE_TYPE.value){
          refill.value.paymentCodeDebit = refill.value.paymentCodeCredit
        }
      } else {
        mask.value = '#####-#####-###########-##'
        IS_MOBILE_TYPE.value = false
      }

      // getComptes()
      fixedAccountCredite()
    }

    // Cameroun
    const regexMtn = new RegExp(/^(6(5[0-4]|[78][0-9])([0-9]){6})$/)
    const regexOrg = new RegExp(/^(6(5[5-9]|9[0-9])[0-9]{6})$/)

    const orangeNumberRules = [
      v => regexOrg.test(String(v).trim()) || 'Numéro au format de l\'opérateur Orange requis',
    ]

    const mtnNumberRules = [
      v => regexMtn.test(String(v)) || 'Numéro au format de l\'opérateur MTN réquis',
    ]

    const phoneNumberRules = computed(() => {
      const type = meansOfPayment.value.filter(el => el === refill.value.paymentCodeDebit)

      if (type.length && parseInt(type[0].type) === TYPE_PAYMENT_MOBILE) {
        if (type[0].code.toUpperCase() === 'OM') {
          return orangeNumberRules
        } if (type[0].code.toUpperCase() === 'MOMO') {
          return mtnNumberRules
        }
      }

      return []
    })

    const addAccountRef = ref(null)

    const getTypePaiementDebit = () => {
      const type = meansOfPayment.value.filter(el => el === refill.value.paymentCodeDebit)

      if (type.length && parseInt(type[0].type) === TYPE_PAYMENT_MOBILE) {
        console.log('type[0]')
        console.log(type[0])
        mask.value = '#########'
        refill.value.compteDebite = ''
        refill.value.paymentCodeCredit = refill.value.paymentCodeDebit
        IS_MOBILE_TYPE.value = true
      } else {
        mask.value = '#####-#####-###########-##'

        IS_MOBILE_TYPE.value = false
      }

      // getComptes()
      // fixedAccountCredite()
    }

    const getTypeDeposit = () => {
      const type = meansOfPayment.value.filter(el => parseInt(el.type) === refill.value.typeDeposit.mobile)
      moyenPayment.value = type

      if(type.length && parseInt(type[0].type) === TYPE_PAYMENT_MOBILE) {
        IS_MOBILE_TYPE.value = true
      }else{
        IS_MOBILE_TYPE.value = false
      }

      refill.value.compteCredite = ''
      refill.value.compteDebite = ''
      refill.value.paymentCodeDebit = ''
      refill.value.paymentCodeCredit = ''
    }

    const comptes = ref([])

    const getComptes = () => {
      const compt = meansOfPayment.value.filter(el => el === refill.value.moyenPaiement)

      console.log(meansOfPayment.value)
      console.log(compt)
      console.log(refill.value)

      comptes.value = compt.length ? compt[0].accounts : []
    }

    const refill = ref({
      moyenPaiement: '',
      typeDeposit: '',
      compteCredite: '',
      compteDebite: '',
      // eslint-disable-next-line radix
      montant: (props.montant === undefined) ? null : parseFloat(props.montant),
      reference: '',
      paymentCodeDebit: '',
      paymentCodeCredit: '',
      facture: null,
      dateRemonte: '',
      menu3: false,
    })

    // eslint-disable-next-line consistent-return,radix
    const paymentByDeposit = computed(() => meansOfPayment.value.filter(payment => parseInt(payment.type) === refill.value.typeDeposit.type))

    const disabled = computed(() => refill.value.typeDeposit.id === DEPOT)

    const applyRules = computed(() => {
      if (refill.value.typeDeposit.id !== DEPOT) {
        return LocalNameRules
      }

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      refill.value.compteCredite = ''
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      refill.value.compteDebite = ''

      return []
    })

    const focusing = ref(false)

    const amountTotext = computed(() => (Number(refill.value.montant).toLocaleString() === 'NaN' ? '' : Number(refill.value.montant).toLocaleString()))

    const resetForm = () => {
      loading.value = true
      nextTick(() => {
        refill.value = {
          moyenPaiement: '',
          typeDeposit: '',
          compteCredite: '',
          compteDebite: '',
          // eslint-disable-next-line radix
          montant: parseInt(props.montant),
          reference: '',
          facture: null,
          paymentCodeDebit: '',
          paymentCodeCredit: '',
          dateRemonte: '',
          menu3: false,
        }
        // refs.refillForm.resetValidation()
        emit('finish')
      })
    }

    const errorModal = ref(false)

    /// Ajouter un compte par moyen de paiement

    const addAccountDialog = ref(false)
    const openAccountDialog = () => {
      if (refill.value.paymentCodeDebit !== '') {
        addAccountDialog.value = true
      } else {
        errorModal.value = true
      }
    }

    const accountByPayment = ref([{
      paymentMean: '',
      paymentAccount: '',
      mask: '',
    }])

    const chgmask = obj => {
      if (obj.paymentMean === '') {
        obj.mask = ''
      } else if (obj.paymentMean.type === TYPE_PAYMENT_MOBILE) {
        obj.mask = '#########'
      } else {
        obj.mask = '#####-#####-###########-##'
      }
    }
    loading.value = false

    const addNewAccount = () => {
      accountByPayment.value.push({
        paymentAccount: '',
      })
    }

    const closeAccountDialog = () => {
      accountByPayment.value = [{
        paymentAccount: '',
      }]

      addAccountDialog.value = false
      addAccountRef.value.resetValidation()
    }

    const disableAccount = computed(() =>

    // disbaled le bouton de la modal d'ajout du compte si une erreur du format du numero de compte existe

      false)

    const { CONTRAT_ID } = useDynamicConstant()

    const addAccount = () => {
      if (addAccountRef.value.validate()) {
        const bankAccount = {}

        bankAccount[refill.value.paymentCodeDebit.code] = accountByPayment.value.map(obj => obj.paymentAccount)

        console.log(CONTRAT_ID)
        Http.post('/save-bank-account', { bankAccount, customerSegmentId: CONTRAT_ID })
          .then(response => {
            console.log(response)
            closeAccountDialog()
            loadAccountByPaymentMethod()
          })
          .catch(error => { console.error(error) })
      }
    }

    const currentDate = moment().format('YYYY-MM-DD')
    const yesterdayDate = moment().subtract(2, 'days').format('YYYY-MM-DD')

    onMounted(() => {
      loadAccountByPaymentMethod()
    })

    return {
      LocalNameRules,
      filesAccepted,
      refillForm,
      typeOfDeposit,
      meansOfPayment,
      moyenPayment,
      loadMeansOfPayment,
      paymentByDeposit,
      getTypePaiement,
      getTypePaiementDebit,
      mask,
      getTypeDeposit,
      getComptes,
      comptes,
      fixedAccountCredite,
      IS_MOBILE_TYPE,

      focusing,
      amountTotext,
      disableAccount,
      loadAccountByPaymentMethod,
      listAccountDebite,

      // paymentByDeposit,
      disabled,
      refill,
      applyRules,
      saveRefill,
      snack,
      resetForm,
      VIREMENT,
      formatDate,
      loading,
      loadPage,
      currency,
      currentDate,
      yesterdayDate,

      addAccountDialog,
      addAccount,
      accountByPayment,
      openAccountDialog,
      chgmask,
      closeAccountDialog,
      addNewAccount,
      phoneNumberRules,
      addAccountRef,
      regexMtn,
      regexOrg,

      errorModal,
      icon: {
        mdiPaperclip,
        mdiCalendarMonth,
        mdiPlus,
        mdiPlusCircle,
        mdiCloseCircle,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.v-file-input__text{
  padding: 5px 0 5px !important;
}

.bg-black{
  background-color: #000000;
}

.rounded-middle{
  border-radius: 50%;
}

.auth-wrapper {
  display: flex;
  min-height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  flex-basis: 100%;
  align-items: center;

  // common style for both v1 and v2
  a {
    text-decoration: unset;
  }

  // auth v1
  &.auth-v1 {
    align-items: center;
    justify-content: center;
    overflow: hidden;
    padding: 1.5rem;
  }
}
</style>
